.printHead {
  display: none;
}

@page {
  size: auto;
  /* Chrome sets own margins, we change these printer settings */
  margin: 12mm 10mm 12mm 10mm;
}

@page: right {
  @bottom-right {
    content: counter(page);
  }
}

@page: left {
  @bottom-left {
    content: counter(page);
  }
}

@media print {
  .table-head {
    background-color: #00589c !important;
    color: #ffffff !important;
    font-size: 9px !important;
  }

  table {
    font-size: 8px !important;
  }

  .printHead {
    display: block;
    font-size: 9px !important;
  }

  .head1 {
    font-size: 11px !important;
    font-weight: bold;
  }

  .head2 {
    font-size: 11px !important;
    font-weight: bold;
  }
}

body > iframe {
  display: none;
}

input[type="file"]::file-selector-button {
  margin-right: 20px;
  border: none;
  background: #00589c;
  padding: 7px 10px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

input[type="file"]::file-selector-button:hover {
  background: #00589c;
}

.iconSize {
  width: 16px;
  height: 16px;
}

.tableColumn {
  float: left;
  width: 50%;
  padding: 0px;
}

/* Clearfix (clear floats) */
.tableRow::after {
  content: "";
  clear: both;
  display: table;
}

.table-head {
  background-color: #00589c;
  color: #ffffff;
  font-weight: bold;
  font-size: 14px;
}

.table-head-upload {
  background-color: #000;
  color: #00589c;
  font-weight: bold;
  font-size: 14px;
}

.table-wrapper {
  max-height: 59vh;
  overflow-y: auto;
  display: inline-block;
}

.ant-menu-item-selected {
  background-color: #2fb2e6 !important;
  color: #ffffff !important;
}

.ant-menu > .ant-menu-item:hover,
.ant-menu > .ant-menu-submenu:hover,
.ant-menu > .ant-menu-item-active,
.ant-menu > .ant-menu-submenu-active,
.ant-menu > .ant-menu-item-open,
.ant-menu > .ant-menu-submenu-open,
.ant-menu > .ant-menu-item-selected,
.ant-menu > .ant-menu-submenu-selected {
  color: #ffffff !important;
  border-bottom: 2px solid #f9ed27;
}

.ant-table table {
  font-size: 11px;
}

.ant-table table tr td {
  font-weight: 500;
  font-family: Poppins, sans-serif;
}
